import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import { Image, Layout, SEO } from '../components';

const IndexPage = (props) => {
  const {
    data: {
      allProjectsJson: {
        edges: PROJECTS,
      },
    },
  } = props;

  const RENDER_PROJECTS = PROJECTS.map((node) => {
    const {
      node: {
        id: ID,
        images: {
          thumbnail: IMAGE,
        },
        slug: SLUG,
        subTitle: SUB_TITLE,
        title: TITLE,
      },
    } = node;

    return (
      <li>
        <Link
          key={ID}
          to={SLUG}
          className="c-card"
        >
          <span className="c-card__media">
            <span className="c-image c-image--47by28">
              <Image
                alt={`${TITLE} thumbnail`}
                draggable={false}
                className="c-image__item"
                src={IMAGE}
              />
            </span>
          </span>
          <span className="c-card__content">
            <span className="c-card__title-group">
              <span className="c-card__title">{TITLE}</span>
              <span className="c-card__sub-title">{SUB_TITLE}</span>
            </span>
          </span>
        </Link>
      </li>
    );
  });

  return (
    <Layout>
      <SEO title="Home" keywords={['ryan potter', 'auckland', 'we developer']} />
      <section className="u-mt--lg">
        <div className="l-container">
          <h2 className="c-heading c-heading--h2 c-heading--compact">
            Ideas, code; presence
            <br />
            specialising in impactful
            <br />
            development &amp; design.
          </h2>
          <div className="l-typography u-mt--sm">
            <p>
              I love creating beautiful code, and cater for all kinds of projects. Whether it be an e-commerce solution, events management system; dashboard, or even a small business portfolio I'm your man. If you would
              like to see some of my work just take a look at my portfolio, or alternatively <a href="mailto:contact@ryanpotter.co.nz" className="c-anchor c-anchor--highlighted">get in contact</a> for more information.
            </p>
            <p>
              The way we view and interact with the web is constantly changing. As an experienced website developer, I help people and companies create an online presence that provides an optimal experience.
            </p>
          </div>
        </div>
      </section>
      <section className="u-mt--lg">
        <div className="l-container">
          <ul className="l-cards u-strip--list u-mt--sm">
            {RENDER_PROJECTS}
          </ul>
        </div>
      </section>
      <section className="u-mt--lg">
        <div className="l-container">
          <h3 className="c-heading c-heading--h2">Services</h3>
          <ul className="l-typography u-strip--list u-mt--sm">
            <li>
              <h4 className="c-heading c-heading--h4">
                Web Development
              </h4>
              <p>
                Creating majestic code since ages ago, I'm a huge fan of anything open source that I can sink my teeth into. All of the sites I create are highly customised, allowing you to choose any features that your
                heart desires.
              </p>
            </li>
            <li>
              <h4 className="c-heading c-heading--h4">
                Consulting
              </h4>
              <p>
                Scoping a new project, and aren't quite sure how to go about it? I am available for consulting whether it be how to build your back-end system, to how to make your front-end responsive for any manner of
                devices.
              </p>
            </li>
            <li>
              <h4 className="c-heading c-heading--h4">
                Hosting
              </h4>
              <p>
                I offer hosting solutions for all of my clients, but if you'd rather have your website sitting somewhere else, that's no problem at all. You own all of the code I create, so you can host your applications
                wherever you choose.
              </p>
            </li>
            <li>
              <h4 className="c-heading c-heading--h4">
                Web Design
              </h4>
              <p>
                I will work with you to create something beautiful, and with purpose to best serve the needs of your audience.
              </p>
            </li>
          </ul>
        </div>
      </section>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    allProjectsJson: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        slug: PropTypes.string,
        subTitle: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  {
    allProjectsJson {
      edges {
        node {
          id
          images {
            thumbnail
          }
          title
          slug
          subTitle
        }
      }
    }
  }
`;
